import React from 'react';
import { useTranslation } from 'react-i18next';
import altel from '../../images/custom/altel2.png';
import beeline from '../../images/custom/beeline.png';
import kcell from '../../images/custom/kcell2.png';
import tele2 from '../../images/custom/tele2.png';

type PaymentContentProps = {
  shouldDisplayBackToSiteButton?: boolean;
  backArrow: () => void;
  payCard?: boolean;
  payPhone?: boolean;
  isExtension?: boolean;
  isFullLoanPayment?: boolean;
  oneCreditCredit365Beecredit?: boolean;
  children: React.ReactNode | undefined;
  backBtn?: string;
  style: { [key: string]: string };
};

export const PaymentContent: React.FC<PaymentContentProps> = ({
  shouldDisplayBackToSiteButton,
  backArrow,
  payCard,
  payPhone,
  isExtension,
  children,
  backBtn,
  style,
  oneCreditCredit365Beecredit,
  isFullLoanPayment
}) => {
  const { t } = useTranslation(['common']);

  const getHeaderMessage = () => {
    switch (true) {
      case isExtension === true:
        return t('payment_date');
      case isExtension === false && isFullLoanPayment === true:
        return t('full_repayment');
      case isExtension === false && isFullLoanPayment === false:
        return t('partial_repayment');
      default:
        return t('repayment');
    }
  };

  return (
    <>
      {(payCard || payPhone) && (
        <div onClick={backArrow} className="back-button-custom">
          <img src={backBtn} alt="" />
          <span className="text-back-btn">{t('common:back')}</span>
        </div>
      )}

      {!oneCreditCredit365Beecredit &&
        !payCard &&
        !payPhone &&
        shouldDisplayBackToSiteButton && (
          <div onClick={backArrow} className="back-button-custom">
            <img src={backBtn} alt="" />
            <span className="text-back-btn">{t('common:back')}</span>
          </div>
        )}

      {!payCard && !payPhone && !oneCreditCredit365Beecredit && (
        <>
          {!isExtension && <h1>{t('repayment')}</h1>}
          {isExtension && <h1>{t('payment_date')}</h1>}
        </>
      )}

      {!payCard && !payPhone && oneCreditCredit365Beecredit && (
        <h1>{getHeaderMessage()}</h1>
      )}

      {payCard && <h1>{t('payCardForm')}</h1>}
      {payPhone && <h1>{t('payPhoneForm')}</h1>}

      {payPhone && (
        <>
          <div className="mobile-icon">
            <img style={{ width: '50px' }} src={tele2} alt="" />
            <img style={{ width: '60px' }} src={altel} alt="" />
            <img style={{ width: '60px' }} src={beeline} alt="" />
            <img style={{ width: '70px' }} src={kcell} alt="" />
          </div>
        </>
      )}

      {children ?? children}

      {payCard && <div className="warning-pay-text">{t('warning_pay')}</div>}

      {!payCard && !payPhone && (
        <div className={style.pay_choice}>{t('Select option')}</div>
      )}
    </>
  );
};
